/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
    padding: 10px 0;
    min-height: auto;
    position: relative;
    margin-right: 5px;

    input[type=checkbox] {
        margin: 0;
        display: none;
        width: 22px;

        + .cr {
            padding-left: 0;

            &:before {
                content: "\e83f";
                width: 22px;
                height: 22px;
                display: inline-block;
                margin-right: 10px;
                border: 2px solid #e9eaec;
                border-radius: 3px;
                font: {
                    size: 15px;
                    family: 'feather';
                    weight: 400;
                }
                line-height: 19px;
                vertical-align: bottom;
                text-align: center;
                background: #ffffff;
                color: transparent;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
            }
        }

        &:checked + .cr:before {
            background: #1dd5d2;
            border-color: #1dd5d2;
            color: #ffffff;
        }

        &.disabled + .cr,
        &:disabled + .cr {
            opacity: 0.5;
        }

        &.disabled + .cr:before,
        &:disabled + .cr:before {
            cursor: not-allowed;
        }
    }

    &.checkbox-fill {
        input[type=checkbox] {
            + .cr {
                &:after {
                    content: "";
                    width: 22.5px;
                    height: 22.5px;
                    display: inline-block;
                    margin-right: 10px;
                    border: 2px solid #e9eaec;
                    border-radius: 2px;
                    vertical-align: bottom;
                    text-align: center;
                    background: transparent;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    position: absolute;
                    top: 8.5px;
                    left: 3px;
                }

                &:before {
                    opacity: 0;
                    content: "\e840";
                    font-size: 27px;
                    background: transparent;
                }
            }

            &:checked {
                + .cr {
                    &:after {
                        opacity: 0;
                    }

                    &:before {
                        opacity: 1;
                        background: transparent;
                        color: #1dd5d2;
                        border-color: transparent;
                    }
                }
            }
        }
    }
    @each $value in $color-bt-name {
        $i: index($color-bt-name, $value);

        &.checkbox-#{$value} input[type=checkbox]:checked + .cr:before {
            background: nth($color-bt-color,$i);
            border-color: nth($color-bt-color,$i);
            color: #ffffff;
        }

        &.checkbox-fill.checkbox-#{$value} input[type=checkbox]:checked + .cr:before {
            background: transparent;
            color: nth($color-bt-color,$i);
            border-color: transparent;
        }
    }

    .cr {
        cursor: pointer;
    }
}

.radio {
    padding: 10px 0;
    min-height: auto;
    position: relative;
    margin-right: 5px;

    input[type=radio] {
        margin: 0;
        display: none;
        width: 22px;

        + .cr {
            padding-left: 0;

            &:after,
            &:before {
                content: "";
                display: inline-block;
                margin-right: 10px;
                border-radius: 50%;
                vertical-align: bottom;
                background: #fff;
                color: transparent;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
            }

            &:before {
                width: 22px;
                height: 22px;
                border: 2px solid #e9eaec;
            }

            &:after {
                width: 12px;
                height: 12px;
                position: absolute;
                top: 12px;
                left: 5px;
                @supports (-webkit-overflow-scrolling: touch) {
                    top: 13px;
                }
                @media (-ms-high-contrast: none), (-ms-high-contrast: active){
                    top: 5px;
                }
            }
        }

        &:checked {
            + .cr {
                &:before {
                    border-color: $primary-color;
                }

                &:after {
                    background: $primary-color;
                }
            }
        }

        &:disabled {
            + .cr {
                opacity: 0.5;
                cursor: not-allowed;

                &:after,
                &:before {
                    cursor: not-allowed;
                }
            }
        }
    }

    &.radio-fill {
        input[type=radio] {
            + .cr {
                &:after {
                    width: 18px;
                    height: 18px;
                    top: 9px;
                    left: 2px;
                    @supports (-webkit-overflow-scrolling: touch) {
                        top: 10px;
                    }
                    @media (-ms-high-contrast: none), (-ms-high-contrast: active){
                        top: 2px;
                    }
                }
            }
        }
    }
    @each $value in $color-bt-name {
        $i: index($color-bt-name, $value);

        &.radio-#{$value} input[type=radio]:checked + .cr {
            &:before {
                border-color: nth($color-bt-color,$i);
            }

            &:after {
                background: nth($color-bt-color,$i);
            }
        }
    }

    .cr {
        cursor: pointer;
    }
}
@-moz-document url-prefix() {
    .radio input[type="radio"] + .cr::after {
        top: 12px;
    }
}
.form-group.d-inline{
    .radio{
        input[type=radio] {
            + .cr {
                &:after {
                    @media (-ms-high-contrast: none), (-ms-high-contrast: active){
                        top: 6px;
                    }
                }
            }
        }
        &.radio-fill{
            input[type=radio] {
                + .cr {
                    &:after {
                        @media (-ms-high-contrast: none), (-ms-high-contrast: active){
                            top: 3px;
                        }
                    }
                }
            }
        }
    }
}
.custom-controls-stacked {
    .radio {
        input[type=radio] {
            + .cr {
                &:after {
                    top: 15px;
                }
            }
        }
    }
}
/**====== Radio & Checked css end ======**/

.checkbox {}

.radio label {
  margin-bottom: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  padding-left: 5px;
}

.checkbox {
  label {
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 10px;
      margin-left: -20px;
      border: 1px solid #5e5e5e;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 10px;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555;
    }
  }

  input {
    &[type="checkbox"], &[type="radio"] {
      opacity: 0;
      z-index: 1;
    }

    &[type="checkbox"]:focus + label::before, &[type="radio"]:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
      font-family: "FontAwesome";
      content: "\f00c";
    }

    &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 13px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &[type="checkbox"]:disabled + label, &[type="radio"]:disabled + label {
      opacity: 0.65;
    }

    &[type="checkbox"]:disabled + label::before, &[type="radio"]:disabled + label::before {
      background-color: #eeeeee;
      cursor: not-allowed;
    }
  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline {
    margin-top: 0;
  }
}

.checkbox-primary input {
  &[type="checkbox"]:checked + label::before, &[type="radio"]:checked + label::before {
    background-color: #4886ff;
    border-color: #4886ff;
  }

  &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
    color: #fff;
  }
}

.checkbox-danger input {
  &[type="checkbox"]:checked + label::before, &[type="radio"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
    color: #fff;
  }
}

.checkbox-info input {
  &[type="checkbox"]:checked + label::before, &[type="radio"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
    color: #fff;
  }
}

.checkbox-warning input {
  &[type="checkbox"]:checked + label::before, &[type="radio"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
    color: #fff;
  }
}

.checkbox-success input {
  &[type="checkbox"]:checked + label::before, &[type="radio"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  &[type="checkbox"]:checked + label::after, &[type="radio"]:checked + label::after {
    color: #fff;
  }
}

.checkbox-primary input {
  &[type="checkbox"]:indeterminate + label::before, &[type="radio"]:indeterminate + label::before {
    background-color: #4886ff;
    border-color: #4886ff;
  }

  &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
    background-color: #fff;
  }
}

.checkbox-danger input {
  &[type="checkbox"]:indeterminate + label::before, &[type="radio"]:indeterminate + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
    background-color: #fff;
  }
}

.checkbox-info input {
  &[type="checkbox"]:indeterminate + label::before, &[type="radio"]:indeterminate + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
    background-color: #fff;
  }
}

.checkbox-warning input {
  &[type="checkbox"]:indeterminate + label::before, &[type="radio"]:indeterminate + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
    background-color: #fff;
  }
}

.checkbox-success input {
  &[type="checkbox"]:indeterminate + label::before, &[type="radio"]:indeterminate + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  &[type="checkbox"]:indeterminate + label::after, &[type="radio"]:indeterminate + label::after {
    background-color: #fff;
  }
}

.radio {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 10px;
      margin-left: -20px;
      border: 1px solid #5e5e5e;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 13px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #555555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }

  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked + label::after {
      -webkit-transform: scale(0.5, 0.5);
      -ms-transform: scale(0.5, 0.5);
      -o-transform: scale(0.5, 0.5);
      transform: scale(0.5, 0.5);
    }

    &:disabled + label {
      opacity: 0.65;

      &::before {
        cursor: not-allowed;
      }
    }
  }

  &.radio-inline {
    margin-top: 0;
  }
}

.radio-primary input[type="radio"] {
  + label::after {
    background-color: #4886ff;
  }

  &:checked + label {
    &::before {
      border-color: #4886ff;
    }

    &::after {
      background-color: #4886ff;
    }
  }
}

.radio-danger input[type="radio"] {
  + label::after {
    background-color: #d9534f;
  }

  &:checked + label {
    &::before {
      border-color: #d9534f;
    }

    &::after {
      background-color: #d9534f;
    }
  }
}

.radio-info input[type="radio"] {
  + label::after {
    background-color: #5bc0de;
  }

  &:checked + label {
    &::before {
      border-color: #5bc0de;
    }

    &::after {
      background-color: #5bc0de;
    }
  }
}

.radio-warning input[type="radio"] {
  + label::after {
    background-color: #f0ad4e;
  }

  &:checked + label {
    &::before {
      border-color: #f0ad4e;
    }

    &::after {
      background-color: #f0ad4e;
    }
  }
}

.radio-success input[type="radio"] {
  + label::after {
    background-color: #5cb85c;
  }

  &:checked + label {
    &::before {
      border-color: #5cb85c;
    }

    &::after {
      background-color: #5cb85c;
    }
  }
}

input {
  &[type="checkbox"].styled:checked + label:after, &[type="radio"].styled:checked + label:after {
    font-family: 'FontAwesome';
    content: "\f00c";
  }

  &[type="checkbox"] .styled:checked + label::before, &[type="radio"] .styled:checked + label::before, &[type="checkbox"] .styled:checked + label::after, &[type="radio"] .styled:checked + label::after {
    color: #fff;
  }
}
