.sw-theme-circles {
  .sw-container {
    min-height: 300px;
  }
  .step-content {
    padding: 10px 0;
    background-color: #fff;
    text-align: left;
  }
  .sw-toolbar {
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0 !important;
  }
  .sw-toolbar-bottom {
    border-top-color: #ddd !important;
    border-bottom-color: #ddd !important;
  }
  > ul.step-anchor {
    position: relative;
    background: #fff;
    border: none;
    list-style: none;
    margin-bottom: 40px;
    &:before {
      content: " ";
      position: absolute;
      top: 50%;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: #f5f5f5;
      border-radius: 3px;
      z-index: 0;
    }
    > li {
      border: none;
      margin-left: 40px;
      z-index: 98;
      > a {
        border: 2px solid #f5f5f5;
        background: #f5f5f5;
        width: 75px;
        height: 75px;
        text-align: center;
        padding: 25px 0;
        border-radius: 50%;
        -webkit-box-shadow: inset 0 0 0 3px #fff !important;
        box-shadow: inset 0 0 0 3px #fff !important;
        text-decoration: none;
        outline-style: none;
        z-index: 99;
        color: #bbb;
        background: #f5f5f5;
        line-height: 1;
        &:hover {
          color: #bbb;
          background: #f5f5f5;
          border-width: 2px;
        }
        > small {
          position: relative;
          bottom: -40px;
          color: #ccc;
        }
      }
      &.clickable > a:hover {
        color: #4285f4 !important;
      }
      &.active > a {
        border-color: #5bc0de;
        color: #fff;
        background: #5bc0de;
        > small {
          color: #5bc0de;
        }
      }
      &.done > a {
        border-color: #5cb85c;
        color: #fff;
        background: #5cb85c;
        > small {
          color: #5cb85c;
        }
      }
      &.danger > a {
        border-color: #d9534f;
        color: #d9534f;
        background: #fff;
        > small {
          color: #d9534f;
        }
      }
      &.disabled > a {
        color: #eee !important;
        &:hover {
          color: #eee !important;
        }
      }
    }
  }
}