/*
    Flaticon icon font: Flaticon
    Creation date: 16/05/2018 08:16
    */
@font-face {
    font-family: "Flaticon";
    src: url("fonts/Flaticon.eot");
    src: url("fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("fonts/Flaticon.woff") format("woff"), url("fonts/Flaticon.ttf") format("truetype"), url("fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: inherit;
    font-style: normal;
    margin-left: inherit;
}

.fi:before {
    display: inline-block;
    font-family: "Flaticon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.flaticon-user:before {
    content: "\f100";
}

.flaticon-money:before {
    content: "\f101";
}

.flaticon-arrows-4:before {
    content: "\f102";
}

.flaticon-calendar-1:before {
    content: "\f103";
}

.flaticon-shopping-label:before {
    content: "\f104";
}

.flaticon-success:before {
    content: "\f105";
}

.flaticon-restaurant:before {
    content: "\f106";
}

.flaticon-star:before {
    content: "\f107";
}

.flaticon-tool-2:before {
    content: "\f108";
}

.flaticon-attention:before {
    content: "\f109";
}

.flaticon-smiley:before {
    content: "\f10a";
}

.flaticon-text:before {
    content: "\f10b";
}

.flaticon-share:before {
    content: "\f10c";
}

.flaticon-upload:before {
    content: "\f10d";
}

.flaticon-message:before {
    content: "\f10e";
}

.flaticon-search:before {
    content: "\f10f";
}

.flaticon-magnifying-glass:before {
    content: "\f110";
}

.flaticon-technology:before {
    content: "\f111";
}

.flaticon-right-align:before {
    content: "\f112";
}

.flaticon-reply:before {
    content: "\f113";
}

.flaticon-loading-arrows:before {
    content: "\f114";
}

.flaticon-right-quote-sign:before {
    content: "\f115";
}

.flaticon-growth:before {
    content: "\f116";
}

.flaticon-picture:before {
    content: "\f117";
}

.flaticon-call-answer:before {
    content: "\f118";
}

.flaticon-pencil-edit-button:before {
    content: "\f119";
}

.flaticon-numbered-list-style:before {
    content: "\f11a";
}

.flaticon-bell:before {
    content: "\f11b";
}

.flaticon-tool-1:before {
    content: "\f11c";
}

.flaticon-folder:before {
    content: "\f11d";
}

.flaticon-arrows-3:before {
    content: "\f11e";
}

.flaticon-arrows-2:before {
    content: "\f11f";
}

.flaticon-badge:before {
    content: "\f120";
}

.flaticon-email:before {
    content: "\f121";
}

.flaticon-maps-and-flags:before {
    content: "\f122";
}

.flaticon-loading:before {
    content: "\f123";
}

.flaticon-reload:before {
    content: "\f124";
}

.flaticon-sign:before {
    content: "\f125";
}

.flaticon-favorite:before {
    content: "\f126";
}

.flaticon-link:before {
    content: "\f127";
}

.flaticon-shapes:before {
    content: "\f128";
}

.flaticon-left-align:before {
    content: "\f129";
}

.flaticon-key:before {
    content: "\f12a";
}

.flaticon-italic-text:before {
    content: "\f12b";
}

.flaticon-information-point:before {
    content: "\f12c";
}

.flaticon-underline:before {
    content: "\f12d";
}

.flaticon-download:before {
    content: "\f12e";
}

.flaticon-arrows-1:before {
    content: "\f12f";
}

.flaticon-gear-option:before {
    content: "\f130";
}

.flaticon-left-arrow:before {
    content: "\f131";
}

.flaticon-signs:before {
    content: "\f132";
}

.flaticon-text-height:before {
    content: "\f133";
}

.flaticon-interface-1:before {
    content: "\f134";
}

.flaticon-black:before {
    content: "\f135";
}

.flaticon-login:before {
    content: "\f136";
}

.flaticon-web:before {
    content: "\f137";
}

.flaticon-arrows:before {
    content: "\f138";
}

.flaticon-tool:before {
    content: "\f139";
}

.flaticon-database:before {
    content: "\f13a";
}

.flaticon-warning:before {
    content: "\f13b";
}

.flaticon-interface:before {
    content: "\f13c";
}

.flaticon-chat:before {
    content: "\f13d";
}

.flaticon-error:before {
    content: "\f13e";
}

.flaticon-attach:before {
    content: "\f13f";
}

.flaticon-cross-out:before {
    content: "\f140";
}

.flaticon-check:before {
    content: "\f141";
}

.flaticon-center-text-interface-symbol-of-lines:before {
    content: "\f142";
}

.flaticon-suitcase:before {
    content: "\f143";
}

.flaticon-shopping-cart:before {
    content: "\f144";
}

.flaticon-graduate-hat:before {
    content: "\f145";
}

.flaticon-clear-button:before {
    content: "\f146";
}

.flaticon-bullet-list:before {
    content: "\f147";
}

.flaticon-bold:before {
    content: "\f148";
}

.flaticon-recycle-bin-filled-tool:before {
    content: "\f149";
}

.flaticon-resize:before {
    content: "\f14a";
}

.flaticon-add-button:before {
    content: "\f14b";
}

.flaticon-switch:before {
    content: "\f14c";
}

.flaticon-file:before {
    content: "\f14d";
}

.flaticon-forms:before {
    content: "\f14e";
}

.flaticon-speech-bubble-couple-of-black-rectangular-shapes:before {
    content: "\f14f";
}

.flaticon-mail:before {
    content: "\f150";
}

.flaticon-calendar:before {
    content: "\f151";
}

.flaticon-desktop-computer-screen-with-rising-graph:before {
    content: "\f152";
}
$font-Flaticon-user: "\f100";
$font-Flaticon-money: "\f101";
$font-Flaticon-arrows-4: "\f102";
$font-Flaticon-calendar-1: "\f103";
$font-Flaticon-shopping-label: "\f104";
$font-Flaticon-success: "\f105";
$font-Flaticon-restaurant: "\f106";
$font-Flaticon-star: "\f107";
$font-Flaticon-tool-2: "\f108";
$font-Flaticon-attention: "\f109";
$font-Flaticon-smiley: "\f10a";
$font-Flaticon-text: "\f10b";
$font-Flaticon-share: "\f10c";
$font-Flaticon-upload: "\f10d";
$font-Flaticon-message: "\f10e";
$font-Flaticon-search: "\f10f";
$font-Flaticon-magnifying-glass: "\f110";
$font-Flaticon-technology: "\f111";
$font-Flaticon-right-align: "\f112";
$font-Flaticon-reply: "\f113";
$font-Flaticon-loading-arrows: "\f114";
$font-Flaticon-right-quote-sign: "\f115";
$font-Flaticon-growth: "\f116";
$font-Flaticon-picture: "\f117";
$font-Flaticon-call-answer: "\f118";
$font-Flaticon-pencil-edit-button: "\f119";
$font-Flaticon-numbered-list-style: "\f11a";
$font-Flaticon-bell: "\f11b";
$font-Flaticon-tool-1: "\f11c";
$font-Flaticon-folder: "\f11d";
$font-Flaticon-arrows-3: "\f11e";
$font-Flaticon-arrows-2: "\f11f";
$font-Flaticon-badge: "\f120";
$font-Flaticon-email: "\f121";
$font-Flaticon-maps-and-flags: "\f122";
$font-Flaticon-loading: "\f123";
$font-Flaticon-reload: "\f124";
$font-Flaticon-sign: "\f125";
$font-Flaticon-favorite: "\f126";
$font-Flaticon-link: "\f127";
$font-Flaticon-shapes: "\f128";
$font-Flaticon-left-align: "\f129";
$font-Flaticon-key: "\f12a";
$font-Flaticon-italic-text: "\f12b";
$font-Flaticon-information-point: "\f12c";
$font-Flaticon-underline: "\f12d";
$font-Flaticon-download: "\f12e";
$font-Flaticon-arrows-1: "\f12f";
$font-Flaticon-gear-option: "\f130";
$font-Flaticon-left-arrow: "\f131";
$font-Flaticon-signs: "\f132";
$font-Flaticon-text-height: "\f133";
$font-Flaticon-interface-1: "\f134";
$font-Flaticon-black: "\f135";
$font-Flaticon-login: "\f136";
$font-Flaticon-web: "\f137";
$font-Flaticon-arrows: "\f138";
$font-Flaticon-tool: "\f139";
$font-Flaticon-database: "\f13a";
$font-Flaticon-warning: "\f13b";
$font-Flaticon-interface: "\f13c";
$font-Flaticon-chat: "\f13d";
$font-Flaticon-error: "\f13e";
$font-Flaticon-attach: "\f13f";
$font-Flaticon-cross-out: "\f140";
$font-Flaticon-check: "\f141";
$font-Flaticon-center-text-interface-symbol-of-lines: "\f142";
$font-Flaticon-suitcase: "\f143";
$font-Flaticon-shopping-cart: "\f144";
$font-Flaticon-graduate-hat: "\f145";
$font-Flaticon-clear-button: "\f146";
$font-Flaticon-bullet-list: "\f147";
$font-Flaticon-bold: "\f148";
$font-Flaticon-recycle-bin-filled-tool: "\f149";
$font-Flaticon-resize: "\f14a";
$font-Flaticon-add-button: "\f14b";
$font-Flaticon-switch: "\f14c";
$font-Flaticon-file: "\f14d";
$font-Flaticon-forms: "\f14e";
$font-Flaticon-speech-bubble-couple-of-black-rectangular-shapes: "\f14f";
$font-Flaticon-mail: "\f150";
$font-Flaticon-calendar: "\f151";
$font-Flaticon-desktop-computer-screen-with-rising-graph: "\f152";
