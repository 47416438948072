// theme-color('primary')
.highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    font-size: 12px;
}

.highcharts-root {
    display: block;

    text {
        stroke-width: 0;
    }
}

.highcharts-strong {
    font-weight: bold;
}

.highcharts-emphasized {
    font-style: italic;
}

.highcharts-anchor {
    cursor: pointer;
}

.highcharts-background {
    fill: #ffffff;
}

.highcharts-label-box,
.highcharts-plot-background,
.highcharts-plot-border {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}

.highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
}

.highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
}
/* Titles */
.highcharts-title {
    fill: #333333;
    font-size: 1.5em;
}

.highcharts-subtitle {
    fill: #666666;
}
/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: #666666;
}

.highcharts-axis-labels {
    fill: #666666;
    cursor: default;
    font-size: 0.9em;
}

.highcharts-grid-line {
    fill: none;
    stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-tick {
    stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: #cccccc;
}

.highcharts-crosshair-category {
    stroke: #ccd6eb;
    stroke-opacity: 0.25;
}
/* Credits */
.highcharts-credits {
    cursor: pointer;
    fill: #999999;
    font-size: 0.7em;
    transition: fill 250ms, font-size 250ms;

    &:hover {
        fill: black;
        font-size: 1em;
    }
}
/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;

    text {
        fill: #333333;
    }

    .highcharts-header {
        font-size: 0.85em;
    }
}

.highcharts-tooltip-box {
    stroke-width: 1px;
    fill: #f7f7f7;
    fill-opacity: 0.85;

    .highcharts-label-box {
        fill: #f7f7f7;
        fill-opacity: 0.85;
    }
}

.highcharts-selection-marker {
    fill: theme-color('primary');
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-state-hover {
    .highcharts-graph {
        stroke-width: 3;
    }

    path {
        transition: stroke-width 50;
        /* quick in */
    }
}

.highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
}
/* Legend hover affects points and series */
.highcharts-data-labels,
.highcharts-markers,
.highcharts-point,
g.highcharts-series {
    transition: opacity 250ms;
}

.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover) {
    opacity: 0.2;
}

.highcharts-legend-series-active {
    .highcharts-data-labels:not(.highcharts-series-hover),
    .highcharts-markers:not(.highcharts-series-hover) {
        opacity: 0.2;
    }
}
/* Series options */
/* Default colors */

.highcharts-color-0 {
    fill: $blue;
    stroke: $blue;
}

.highcharts-color-1 {
    fill: $gray-800;
    stroke: $gray-800;
}

.highcharts-color-2 {
    fill: $green;
    stroke: $green;
}

.highcharts-color-3 {
    fill: $red;
    stroke: $red;
}

.highcharts-color-4 {
    fill: $indigo;
    stroke: $indigo;
}

.highcharts-color-5 {
    fill: $pink;
    stroke: $pink;
}

.highcharts-color-6 {
    fill: $yellow;
    stroke: $yellow;
}

.highcharts-color-7 {
    fill: $teal;
    stroke: $teal;
}

.highcharts-color-8 {
    fill: $orange;
    stroke: $orange;
}

.highcharts-color-9 {
    fill: $cyan;
    stroke:$cyan;
}

.highcharts-area {
    fill-opacity: 0.75;
    stroke-width: 0;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: #ffffff;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
}

.highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
    fill: #333333;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-markers .highcharts-point-select,
.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select {
    fill: #cccccc;
    stroke: #000000;
}

.highcharts-column-series {
    rect.highcharts-point {
        stroke: #ffffff;
    }

    .highcharts-point {
        transition: fill-opacity 250ms;
    }

    .highcharts-point-hover {
        fill-opacity: 0.75;
        transition: fill-opacity 50ms;
    }
}

.highcharts-pie-series {
    .highcharts-point {
        stroke-linejoin: round;
        stroke: #ffffff;
    }

    .highcharts-point-hover {
        fill-opacity: 0.75;
        transition: fill-opacity 50ms;
    }
}

.highcharts-funnel-series,
.highcharts-pyramid-series {
    .highcharts-point {
        stroke-linejoin: round;
        stroke: #ffffff;
    }

    .highcharts-point-hover {
        fill-opacity: 0.75;
        transition: fill-opacity 50ms;
    }

    .highcharts-point-select {
        fill: inherit;
        stroke: inherit;
    }
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series {
    .highcharts-point {
        stroke-width: 1px;
        stroke: #e6e6e6;
        transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
    }

    .highcharts-point-hover {
        stroke: #999999;
        transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
    }

    .highcharts-above-level {
        display: none;
    }

    .highcharts-internal-node {
        fill: none;
    }

    .highcharts-internal-node-interactive {
        fill-opacity: 0.15;
        cursor: pointer;

        &:hover {
            fill-opacity: 0.75;
        }
    }
}
/* Legend */
.highcharts-legend-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-legend-item {
    > text {
        fill: #333333;
        font-weight: bold;
        font-size: 1em;
        cursor: pointer;
        stroke-width: 0;
    }

    &:hover text {
        fill: #000000;
    }
}

.highcharts-legend-item-hidden * {
    fill: #cccccc !important;
    stroke: #cccccc !important;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: theme-color('primary');
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: #cccccc;
}

circle {
    &.highcharts-legend-nav-active,
    &.highcharts-legend-nav-inactive {
        /* tracker */
        fill: rgba(192, 192, 192, 0.0001);
    }
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}
/* Bubble legend */
.highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
    stroke-width: 1;
}

.highcharts-bubble-legend-labels {
    fill: #333333;
}
/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: #ffffff;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}
/* Plot bands and polar pane backgrounds */
.highcharts-pane,
.highcharts-plot-band {
    fill: #000000;
    fill-opacity: 0.05;
}

.highcharts-plot-line {
    fill: none;
    stroke: #999999;
    stroke-width: 1px;
}
/* Highcharts More and modules */
.highcharts-boxplot-box {
    fill: #ffffff;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: #000000;
}

.highcharts-gauge-series {
    .highcharts-data-label-box {
        stroke: #cccccc;
        stroke-width: 1px;
    }

    .highcharts-dial {
        fill: #000000;
        stroke-width: 0;
    }
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: #333333;
    stroke-dasharray: 1, 3;
}

.highcharts-sankey-series {
    .highcharts-point {
        stroke-width: 0;
    }

    .highcharts-link {
        transition: fill 250ms, fill-opacity 250ms;
        fill-opacity: 0.5;
    }

    .highcharts-point-hover.highcharts-link {
        transition: fill 50ms, fill-opacity 50ms;
        fill-opacity: 1;
    }
}

.highcharts-venn-series {
    .highcharts-point {
        fill-opacity: 0.75;
        stroke: #cccccc;
        transition: stroke 250ms, fill-opacity 250ms;
    }

    .highcharts-point-hover {
        fill-opacity: 1;
        stroke: #cccccc;
    }
}
/* Highstock */
.highcharts-navigator-mask-outside {
    fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
    fill: theme-color('primary');
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: #cccccc;
    fill: none;
}

.highcharts-navigator-handle {
    stroke: #cccccc;
    fill: #f2f2f2;
    cursor: ew-resize;
}

.highcharts-navigator-series {
    fill: theme-color('primary');
    stroke: theme-color('primary');

    .highcharts-graph {
        stroke-width: 1px;
    }

    .highcharts-area {
        fill-opacity: 0.05;
    }
}

.highcharts-navigator-xaxis {
    .highcharts-axis-line {
        stroke-width: 0;
    }

    .highcharts-grid-line {
        stroke-width: 1px;
        stroke: #e6e6e6;
    }

    &.highcharts-axis-labels {
        fill: #999999;
    }
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: #cccccc;
    stroke: #cccccc;
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: #e6e6e6;
    stroke: #cccccc;
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: #666666;
}

.highcharts-scrollbar-rifles {
    stroke: #666666;
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #f2f2f2;
    stroke-width: 1px;
}

.highcharts-button {
    fill: #f7f7f7;
    stroke: #cccccc;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;

    text {
        fill: #333333;
    }
}

.highcharts-button-hover {
    transition: fill 0;
    fill: #e6e6e6;
    stroke: #cccccc;

    text {
        fill: #333333;
    }
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: #e6ebf5;
    stroke: #cccccc;

    text {
        fill: #333333;
        font-weight: bold;
    }
}

.highcharts-button-disabled text {
    fill: #333333;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0;
}

.highcharts-range-label {
    rect {
        fill: none;
    }

    text {
        fill: #666666;
    }
}

.highcharts-range-input {
    rect {
        fill: none;
    }

    text {
        fill: #333333;
    }
    stroke-width: 1px;
    stroke: #cccccc;
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
}

.highcharts-crosshair-label {
    text {
        fill: #ffffff;
        font-size: 1.1em;
    }

    .highcharts-label-box {
        fill: inherit;
    }
}

.highcharts-candlestick-series {
    .highcharts-point {
        stroke: #000000;
        stroke-width: 1px;
    }

    .highcharts-point-up {
        fill: #ffffff;
    }
}

.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series {
    .highcharts-point .highcharts-label-box {
        stroke: #999999;
        fill: #ffffff;
        transition: fill 250ms;
    }

    .highcharts-point-hover .highcharts-label-box {
        stroke: #000000;
        fill: #ccd6eb;
    }

    .highcharts-point text {
        fill: #000000;
        font-size: 0.9em;
        font-weight: bold;
    }
}
/* Highmaps */
.highcharts-map-series {
    .highcharts-point {
        transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
        stroke: #cccccc;
    }

    .highcharts-point-hover {
        transition: fill 0ms, fill-opacity 0;
        fill-opacity: 0.5;
        stroke-width: 2px;
    }
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: #999999;
}

.highcharts-null-point {
    fill: #f7f7f7;
}
/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}
/* Exporting module */
.highcharts-contextbutton {
    fill: #ffffff;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;

    &:hover {
        fill: #e6e6e6;
        stroke: #e6e6e6;
    }
}

.highcharts-button-symbol {
    stroke: #666666;
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid #999999;
    background: #ffffff;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: #333333;
    cursor: pointer;
    transition: background 250ms, color 250ms;

    &:hover {
        background: theme-color('primary');
        color: #ffffff;
    }
}
/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-axis-label,
.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label {
    cursor: pointer;
    fill: theme-color('primary');
    font-weight: bold;
    text-decoration: underline;
}
/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: #666666;
}
/* Drag-panes module */
.highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
}
/* Bullet type series */
.highcharts-bullet-target {
    stroke-width: 0;
}
/* Lineargauge type series */
.highcharts-lineargauge-target,
.highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: #333333;
}
/* Annotations module */
.highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #000000;
    fill: #000000;
    fill-opacity: 0.75;
}

.highcharts-annotation-label text {
    fill: #e6e6e6;
}
/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
    cursor: pointer;
}

.highcharts-point-connecting-path {
    fill: none;
}

.highcharts-grid-axis {
    .highcharts-axis-line,
    .highcharts-tick {
        stroke-width: 1px;
    }
}
